*, body {
    margin: 0;
    padding : 0;
}

@font-face { 
    font-family: 'Roboto';
    src: url('../../assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap; 
}  

@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;   
    font-display: swap;  
}    
 
@font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;   
    font-display: swap;  
}  
  
 